<template>
  <div v-loading="loading" class="table-area" style="height:10px">
    <el-row class="table-top" style="height:10px">
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <h4 class="heading">Active Position Clients</h4>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <div class="search-wrapper"></div>
      </el-col>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="btn-wrapper"></div>
      </el-col>
    </el-row>

    <div>
      <el-card>
        <div class="table_wrapper">
          <table class="table" border="1" width="100%">
            <tbody>
              <tr>
                <th>
                  <p>ID :</p>
                </th>
                <td>
                  <p></p>
                </td>
                <th>
                  <p>Commodity</p>
                </th>
                <td>
                  <p></p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>User :</p>
                </th>
                <td>
                  <p>{{ viewData.user_name }}</p>
                </td>
                <th>
                  <p>Trade:</p>
                </th>
                <td>
                  <p>{{ viewData.type }}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Rate :</p>
                </th>
                <td>
                  <p>{{ viewData.price }}</p>
                </td>
                <th>
                  <p>Lots :</p>
                </th>
                <td>
                  <p>{{viewData.unit}}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Condition :</p>
                </th>
                <td>
                  <p></p>
                </td>
                <th>
                  <p>Status :</p>
                </th>
                <td>
                  <p>{{ viewData.status_text }}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Date :</p>
                </th>
                <td>
                  <p>{{ viewData.order_placed }}</p>
                </td>
                <th>
                  <p>Executed On :</p>
                </th>
                <td>
                  <p>{{ viewData.order_placed }}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Cancelled On :</p>
                </th>
                <td>
                  <p>{{ viewData.cancelled_at }}</p>
                </td>
                <th>
                  <p>Ip Address :</p>
                </th>
                <td>
                  <p></p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { show } from "@/api/pending-order";

export default {
  name: "PendingOrders",
  components: {},
  data() {
    return {
      loading: false,

      viewData: {},
    };
  },
  computed: {},
  created() {
    this.id = this.$route.params.id;
    console.log(this.$route.params.id);
    this.getPendingOrderDetails();
  },
  methods: {
    // //////////////////
    // GET LIST DATA
    // //////////////////
    handlePagination() {
      this.getPendingOrderDetails();
    },
    handleSearch() {
      this.listQuery.page = 1;
      this.getPendingOrderDetails();
    },
    getPendingOrderDetails() {
      this.loading = true;
      show(this.$route.params.id).then((response) => {
        this.viewData = response.data.data.data;
        console.log(this.viewData);
        this.loading = false;
      });
    },
  },
};
</script>
